import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { getAccount } from '../../../shared/services/account_service';
import { isReadOnly } from '../../../shared/services/authentication_service';
import Mapper from '../../../shared/util/mapper';
import ErrorHandler from '../../../shared/util/error_handler';
import StringUtil from '../../../shared/util/string_util';
import Table from "../../../shared/components/table";
import LoadingText from '../../../shared/components/loading_text';
import { hasModule } from '../../../shared/services/environment_service';
import { getInvoiceGroups } from '../../../shared/services/invoice_service';
import RowDataTable from '../../../shared/components/row_data_table';
import TableUtil from '../../../shared/util/table_util';
import Converter from '../../../shared/util/converter';

class Account extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      account_id: this.props.match.params.account_id,
      account: {}
    }

    this.rowTableRef = React.createRef();
  }

  componentDidMount() {
    this.getAccount();
  }

  getAccount() {
    getAccount(this.state.account_id).then((account) => {
      if (account) {
        this.setState({ account: account }, () => {
          this.setAddressRows();
          this.setUserRows();
          this.setConsigneeRows();
        });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getAddressHeaders() {
    return [
      { name: "Name", key: "name" },
      { name: "Address line", key: "address" },
      { name: "Postcal code", key: "postal_code" },
      { name: "City", key: "city" },
      { name: "Port name", key: "port_name" },
      { name: "Country", key: "country_name" }
    ];
  }
  
  setAddressRows() {
    var rows = [];
    this.state.account.addresses.forEach(address => {
      rows.push({
        id: address.id,
        name: address.name,
        address: address.address,
        postal_code: address.postal_code,
        city: address.city,
        port_name: address.port_name,
        country_name: address.country.name
      });
    });
    this.setState({ address_rows: rows });
  }

  getUserHeaders() {
    return [
      { name: "Email", key: "email" },
      { name: "Full name", key: "full_name" },
      { name: "Role", key: "role" }
    ];
  }

  setUserRows() {
    var rows = [];
    this.state.account.users.forEach(user => {
      rows.push({
        id: user.id,
        email: user.email,
        full_name: user.full_name,
        role: Mapper.fromUserRole(user.role)
      });
    });
    this.setState({ user_rows: rows });
  }

  getRelationsHeaders() {
    return [
      { name: "Name", key: "name" }
    ];
  }
  
  setConsigneeRows() {
    var rows = [];
    this.state.account.relations.forEach(consignee => {
      rows.push({
        id: consignee.id,
        name: consignee.name
      });
    });
    this.setState({ consignee_rows: rows });
  }

  getInvoiceGroupRows(page, blockSize, sort, filters) {
    return new Promise((resolve, reject) => {
      return getInvoiceGroups(this.state.account_id, page, blockSize, sort, JSON.stringify(filters))
      .then((response) => {
        if(!response) {
          reject("no data");
        }

        resolve({ content: response.content, total_elements: response.total_elements });
      }).catch(error => {
        reject(error);
      });
    });
  };
  
  getInvoiceGroupsHeaders() {
    var headers = [];
    headers.push(TableUtil.getTextColumn("name", "Name"));
    headers.push(TableUtil.getSetColumn(
      "status",
      "Status",
      (params) => Mapper.fromInvoiceGroupStatus(params.value),
      Object.keys(Mapper.invoiceGroupStatuses()),
      (value) => Mapper.fromInvoiceGroupStatus(value)
    ));
    headers.push(TableUtil.getDateColumn("created_at", "Created at", (params) => Converter.toDate(params.value)));
    return headers;
  }

  openInvoiceGroup(event) {
    var newTab = event.event.ctrlKey || event.event.metaKey;
    var url = `/accounts/${this.state.account_id}/invoice-groups/${event.data.id}${this.props.location.search}`
    if(newTab) {
      window.open(url, "_blank", "noreferrer");
    } else {
      this.props.history.push(url);
    }
  }

  render() {
    return (
      <div>
          
        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem><Link to={`/accounts` + this.props.location.search}>Accounts</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.account.name}</BreadcrumbItem>
          </Breadcrumb>
          
          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Company information</label>
                </div>
              </div>
            </div>
            <div className="row row-small">
              <div className="col-sm-6">
                <table className="table table-details" >
                  <tbody>
                    <tr className="row-no-top-border-and-padding">
                      <td style={{width: "230px"}} className="header-text">ID</td>
                      <td><LoadingText text={StringUtil.shortId(this.state.account.id)} /></td>
                    </tr>
                    <tr>
                      <td width={200} className="header-text">Account type</td>
                      <td><LoadingText text={StringUtil.capitalize(this.state.account.type)} /></td>
                    </tr>
                    <tr>
                      <td width={200}  className="header-text">Name</td>
                      <td><LoadingText text={this.state.account.name} /></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Addresses</label>
                </div>
              </div>
            </div>

            <div className="row row-small">
              <div className="col-sm">
                <Table
                  loading_rows={4}
                  headers={this.getAddressHeaders()}
                  rows={this.state.address_rows}
                  selectable={false}
                  empty_rows_text="There are no addresses"
                />
              </div>
            </div>
            
          </div>

          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Users</label>
                </div>
              </div>
            </div>

            <div className="row row-small">
              <div className="col-sm">
                <Table
                  loading_rows={4}
                  headers={this.getUserHeaders()}
                  rows={this.state.user_rows}
                  selectable={false}
                  empty_rows_text="There are no users"
                />
              </div>
            </div>
            
          </div>

          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Relations</label>
                </div>
              </div>
            </div>

            <div className="row row-small">
              <div className="col-sm">
                <Table
                  loading_rows={4}
                  headers={this.getRelationsHeaders()}
                  rows={this.state.consignee_rows}
                  selectable={false}
                  empty_rows_text="There are no relations"
                />
              </div>
            </div>
            
          </div>

          {hasModule("invoicing") &&
            <div className="container-body">
              <div className="row">
                <div className="col-md">
                  <div className="form-header">
                    <label>Invoice groups</label>
                  </div>
                </div>
              </div>

              { !isReadOnly() &&
                <div className="row row-small">
                  <div className="col-sm">
                    <Link className="link-button-wrapper" to={`/accounts/${this.state.account_id}/invoice-groups/create` + this.props.location.search}>
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<Icon>add</Icon>}>
                        Add
                      </Button>
                    </Link>
                  </div>
                </div>
              }

              <div className="row">
                <div className="col-sm">
                  <RowDataTable
                    ref={this.rowTableRef}
                    name="invoice_groups_table_state"
                    rowModelType="serverSide"
                    rowData={{ totalElements: 0, ready: false }}
                    getRows={this.getInvoiceGroupRows.bind(this)}
                    columnDefs={this.getInvoiceGroupsHeaders()}
                    overlayNoRowsTemplate="There are no invoice groups"
                    pagination={true}
                    defaultPageSize={10}
                    onRowClicked={this.openInvoiceGroup.bind(this)}
                  />
                </div>
              </div>
              
            </div>
          }
          
        </div>
      </div >
    );
  }
}

export default Account;
