import React from 'react';

class Mapper extends React.Component {

  static fromAccountType(key) {
    var lookup = this.accountTypes()[key];
    if(!lookup) {
      return "-";
    }
    return lookup;
  }

  static accountTypes() {
    return {
      "customer": "Customer",
      "forwarder": "Forwader",
      "agent": "Agent",
      "admin": "Admin",
    };
  }

  static fromQuoteStatus(key) {
    var lookup = this.quoteStatuses()[key];
    if (!lookup) {
      return "-";
    }
    return lookup;
  }

  static fromQuoteStatusValue(value) {
    return Object.entries(this.quoteStatuses()).find(s => s[1] === value)[0];
  }

  static quoteStatuses() {
    return {
      "pending": "Pending",
      "quoted": "Quoted",
      "accepted": "Accepted",
      "declined": "Declined",
      "expired": "Expired",
      "deleted": "Deleted"
    };
  }

  static filterableQuoteStatuses() {
    return {
      "pending": "Pending",
      "quoted": "Quoted",
      "accepted": "Accepted",
      "declined": "Declined",
      "expired": "Expired"
    };
  }

  static toQuoteStatusBadge(key) {
    return {
      "pending": "badge",
      "quoted": "badge-active",
      "accepted": "badge-completed",
      "declined": "badge-error",
      "expired": "badge-error",
      "deleted": "badge-error"
    }[key];
  }

  static fromShipmentStatus(key) {
    var lookup = this.shipmentStatuses()[key];
    if (!lookup) {
      return "-";
    }
    return lookup;
  }

  static fromShipmentStatusValue(value) {
    return Object.entries(this.shipmentStatuses()).find(s => s[1] === value)[0];
  }

  static shipmentStatuses() {
    return {
      "opened": "Opened",
      "ready": "Ready",
      "received": "Received",
      "in_transit": "In Transit",
      "at_destination": "At Destination",
      "delivered": "Delivered",
      "completed": "Completed"
    };
  }

  static agentShipmentStatuses() {
    return {
      "opened": "Opened",
      "ready": "Ready",
      "received": "Received",
      "in_transit": "In Transit",
      "at_destination": "At Destination",
      "delivered": "Delivered"
    };
  }

  static toShipmentStatusBadge(key) {
    return {
      "opened": "badge-opened",
      "ready": "badge-ready",
      "received": "badge-received",
      "in_transit": "badge-in-transit",
      "at_destination": "badge-at-destination",
      "delivered": "badge-delivered",
      "completed": "badge-completed"
    }[key];
  }

  static fromIncoterms(key) {
    var lookup = this.incoterms()[key];
    if (!lookup) {
      return "-";
    }
    return lookup;
  }

  static incoterms() {
    return {
      "exw": "EXW – Ex Works",
      "fca": "FCA – Free Carrier",
      "fas": "FAS – Free Alongside Ship",
      "fob": "FOB – Free on Board",
      "cfr": "CFR – Cost and Freight",
      "cif": "CIF – Cost, Insurance & Freight",
      "cpt": "CPT – Carriage Paid To",
      "cip": "CIP – Carriage and Insurance Paid To",
      "dpu": "DPU – Delivered at Place Unloaded",
      "dap": "DAP – Delivered at Place",
      "ddp": "DDP – Delivered Duty Paid"
    };
  }

  static fromIncotermsShort(key) {
    var lookup = this.incotermsShort()[key];
    if (!lookup) {
      return "-";
    }
    return lookup;
  }

  static fromIncotermsShortValue(value) {
    return Object.entries(this.incotermsShort()).find(s => s[1] === value)[0];
  }

  static incotermsShort() {
    return {
      "exw": "EXW",
      "fca": "FCA",
      "fas": "FAS",
      "fob": "FOB",
      "cfr": "CFR",
      "cif": "CIF",
      "cpt": "CPT",
      "cip": "CIP",
      "dpu": "DPU",
      "dap": "DAP",
      "ddp": "DDP"
    };
  }

  static fromBusinessType(key) {
    var lookup = this.businessTypes()[key];
    if (!lookup) {
      return "-";
    }
    return lookup;
  }

  static businessTypes() {
    return {
      "b2c": "B2C – Business to Consumer",
      "b2b": "B2B – Business to Business"
    };
  }

  static fromBusinessTypeShort(key) {
    var lookup = this.businessTypesShort()[key];
    if (!lookup) {
      return "-";
    }
    return lookup;
  }

  static fromBusinessTypeShortValue(value) {
    return Object.entries(this.businessTypesShort()).find(s => s[1] === value)[0];
  }

  static businessTypesShort() {
    return {
      "b2c": "B2C",
      "b2b": "B2B"
    };
  }

  static fromCurrency(key) {
    var lookup = this.currencies()[key];
    if (!lookup) {
      return "-";
    }
    return lookup;
  }

  static currencies() {
    return {
      "eur": "€ – Euro",
      "usd": "$ – US Dollar"
    };
  }

  static fromCurrencyShort(key) {
    var lookup = this.currenciesShort()[key];
    if (!lookup) {
      return "-";
    }
    return lookup;
  }

  static currenciesShort() {
    return {
      "eur": "€",
      "usd": "US$"
    };
  }

  static fromTransportType(key) {
    var lookup = this.transportTypes()[key];
    if (!lookup) {
      return "-";
    }
    return lookup;
  }

  static transportTypes() {
    return {
      "ftl": "Full Truck Load (FTL)",
      "ltl": "Less than Truck Load (LTL)",
      "fcl": "Full Container Load (FCL)",
      "lcl": "Less than Container Load (LCL)"
    };
  }

  static fromTruckFuelType(key) {
    var lookup = this.truckFuelTypes()[key];
    if (!lookup) {
      return "-";
    }
    return lookup;
  }

  static truckFuelTypes() {
    return {
      "diesel": "Diesel",
      "petrol": "Petrol",
      "lpg": "LPG",
      "lng": "LNG",
      "cng": "CNG",
      "electricity": "Electricity"
    };
  }

  static fromTrackingStatus(key) {
    var lookup = this.trackingStatuses()[key];
    if (!lookup) {
      return "-";
    }
    return lookup;
  }

  static trackingStatuses() {
    return {
      "in_progress": "In progress",
      "booked": "Booked",
      "loaded": "Loaded",
      "sailing": "Sailing",
      "arrived": "Arrived",
      "discharged": "Discharged",
      "untracked": "Untracked",
      "skipped": "Skipped"
    };
  }

  static fromLocationType(key) {
    var lookup = this.locationTypes()[key];
    if (!lookup) {
      return "-";
    }
    return lookup;
  }

  static locationTypes() {
    return {
      "address": "Address",
      "warehouse": "Warehouse",
      "port": "Port",
      "none": "None"
    };
  }

  static fromInsuranceType(key) {
    var lookup = this.insuranceTypes()[key];
    if (!lookup) {
      return "-";
    }
    return lookup;
  }

  static insuranceTypes() {
    return {
      "full": "Full (Cargo value + Shipping costs)",
      "cargo_value": "Cargo value",
      "none": "None"
    };
  }

  static fromTransportTypeShort(key) {
    var lookup = this.transportTypesShort()[key];
    if (!lookup) {
      return "-";
    }
    return lookup;
  }

  static fromTransportTypeShortValue(value) {
    return Object.entries(this.transportTypesShort()).find(s => s[1] === value)[0];
  }

  static transportTypesShort() {
    return {
      "ftl": "FTL",
      "ltl": "LTL",
      "fcl": "FCL",
      "lcl": "LCL"
    };
  }

  static fromContainerSize(key) {
    var lookup = this.containerSizes()[key];
    if (!lookup) {
      return "-";
    }
    return lookup;
  }

  static containerSizes() {
    return {
      "20ft": "20ft",
      "40ft": "40ft"
    };
  }

  static fromContainerType(key) {
    var lookup = this.containerTypes()[key];
    if (!lookup) {
      return "-";
    }
    return lookup;
  }

  static containerTypes() {
    return {
      "standard": "Standard",
      "high_cube": "High Cube",
      "open_top": "Open Top",
      "flat_rack": "Flat Rack",
      "platform": "Platform"
    };
  }

  static fromLoadingStatus(key) {
    var lookup = this.loadingStatuses()[key];
    if (!lookup) {
      return "-";
    }
    return lookup;
  }

  static loadingStatuses() {
    return {
      "deviation": "Deviation",
      "complete": "Complete"
    };
  }

  static toLoadingStatusBadge(key) {
    return {
      "deviation": "badge-deviation",
      "complete": "badge-complete",
    }[key];
  }

  static fromScanLoadingStatus(key) {
    var lookup = this.scanLoadingStatuses()[key];
    if (!lookup) {
      return "-";
    }
    return lookup;
  }

  static scanLoadingStatuses() {
    return {
      "todo": "To do",
      "deviation": "Deviation",
      "complete": "Complete"
    };
  }

  static toScanLoadingStatusBadge(key) {
    return {
      "todo": "badge-todo",
      "deviation": "badge-deviation",
      "complete": "badge-complete",
    }[key];
  }

  static fromInvoiceStatus(key) {
    var lookup = this.invoiceStatuses()[key];
    if (!lookup) {
      return "-";
    }
    return lookup;
  }

  static fromInvoiceStatusValue(value) {
    return Object.entries(this.invoiceStatuses()).find(s => s[1] === value)[0];
  }

  static invoiceStatuses() {
    return {
      "open": "Open",
      "paid": "Paid"
    };
  }

  static toInvoiceStatusBadge(key) {
    return {
      "open": "badge",
      "paid": "badge-completed"
    }[key];
  }

  static fromInvoiceType(key) {
    var lookup = this.invoiceTypes()[key];
    if (!lookup) {
      return "-";
    }
    return lookup;
  }

  static invoiceTypes() {
    return {
      "sale": "Sale",
      "purchase": "Purchase"
    };
  }

  static fromInvoiceGroupStatus(key) {
    var lookup = this.invoiceGroupStatuses()[key];
    if (!lookup) {
      return "-";
    }
    return lookup;
  }

  static invoiceGroupStatuses() {
    return {
      "open": "Open",
      "closed": "Closed"
    };
  }

  static fromStockHistoryType(key) {
    var lookup = this.stockHistoryTypes()[key];
    if (!lookup) {
      return "-";
    }
    return lookup;
  }

  static stockHistoryTypes() {
    return {
      "in": "In",
      "out": "Out"
    };
  }

  static toStockHistoryTypeBadge(key) {
    return {
      "in": "badge",
      "out": "badge"
    }[key];
  }


  static fromStockHistoryStatus(key) {
    var lookup = this.stockHistoryStatuses()[key];
    if (!lookup) {
      return "-";
    }
    return lookup;
  }

  static stockHistoryStatuses() {
    return {
      "pending": "Pending",
      "finished": "Finished"
    };
  }

  static toStockHistoryStatusBadge(key) {
    return {
      "pending": "badge",
      "finished": "badge-completed"
    }[key];
  }

  static fromDateRange(key) {
    var lookup = this.dateRanges()[key];
    if (!lookup) {
      return "-";
    }
    return lookup;
  }

  static dateRanges() {
    return {
      "this_month": "This month",
      "last_month": "Last month",
      "last_three_months": "Last 3 months",
      "this_year": "This year",
      "last_year": "Last year",
      "custom": "Custom",
    };
  }

  static fromBoolean(key) {
    var lookup = this.booleans()[key];
    if (!lookup) {
      return "-";
    }
    return lookup;
  }

  static booleans() {
    return {
      "true": "Yes",
      "false": "No"
    };
  }

  static fromUserRole(key) {
    var lookup = this.userRoles()[key];
    if (!lookup) {
      return "-";
    }
    return lookup;
  }

  static userRoles() {
    return {
      "read_only": "Read only",
      "default": "Default",
      "admin": "Admin"
    };
  }

  static fromUserNotifications(key) {
    var lookup = this.userNotifications()[key];
    if (!lookup) {
      return "-";
    }
    return lookup;
  }

  static userNotifications() {
    return {
      "all": "All",
      "mine": "Mine",
      "none": "None"
    };
  }

  static toCompanyDetails(company_details) {
    return {
      "name": company_details.name
    };
  }

  static toUserUpdate(user_update) {
    return {
      "password": user_update.password,
      "terms": user_update.terms,
      "full_name": user_update.full_name,
      "notifications": user_update.notifications
    };
  }

  static toAdminUserUpdate(user_update) {
    return {
      "role": user_update.role,
      "full_name": user_update.full_name
    };
  }

  static toAccountCreation(account) {
    return {
      "email": account.email,
      "password": account.password,
      "partner_id": account.partner_id
    };
  }

  static toAddressCreation(address) {
    return {
      "name": address.name,
      "address": address.address,
      "postal_code": address.postal_code,
      "city": address.city,
      "port_name": address.port_name,
      "country_code": address.country.country_code
    };
  }

  static toWarehouseCreation(address) {
    return {
      "name": address.name,
      "address": address.address,
      "postal_code": address.postal_code,
      "city": address.city,
      "country_code": address.country.country_code
    };
  }

  static toQuoteRequest(quote) {
    return {
      "reference": quote.reference,
      "incoterms": quote.incoterms,
      "pickup_location": this.toLocation(quote.pickup_location),
      "delivery_location": this.toLocation(quote.delivery_location),
      "business_type": quote.business_type,
      "shipper": this.toIdObject(quote.shipper),
      "shipper_name": quote.shipper_name,
      "shipper_phone": quote.shipper_phone,
      "shipper_email": quote.shipper_email,
      "consignee": this.toIdObject(quote.consignee),
      "consignee_name": quote.consignee_name,
      "consignee_phone": quote.consignee_phone,
      "consignee_email": quote.consignee_email,
      "currency": quote.currency,
      "cargo_description": quote.cargo_description,
      "cargo_value": quote.cargo_value,
      "insurance": quote.insurance,
      "transport_type": quote.transport_type,
      "containers": this.toQuoteContainer(quote.containers),
      "truck_quantity": quote.truck_quantity,
      "pallets": this.toQuotePallets(quote.pallets),
      "colli": this.toQuotePackages(quote.colli),
      "comment": quote.comment
    };
  }

  static toQuoteCalculation(quote) {
    return {
      "reference": quote.reference,
      "incoterms": quote.incoterms,
      "pickup_location": this.toLocation(quote.pickup_location),
      "delivery_location": this.toLocation(quote.delivery_location),
      "business_type": quote.business_type,
      "consignee_name": quote.consignee_name,
      "consignee_phone": quote.consignee_phone,
      "consignee_email": quote.consignee_email,
      "currency": quote.currency,
      "cargo_description": quote.cargo_description,
      "cargo_value": quote.cargo_value,
      "insurance": quote.insurance,
      "transport_type": quote.transport_type,
      "containers": this.toQuoteContainer(quote.containers),
      "truck_quantity": quote.truck_quantity,
      "pallets": this.toQuotePallets(quote.pallets),
      "colli": this.toQuotePackages(quote.colli),
      "include_margin": quote.include_margin
    };
  }

  static toShipmentRequest(shipment) {
    return {
      "customer": this.toIdObject(shipment.customer),
      "customer_contact": this.toIdObject(shipment.customer_contact),
      "quote": this.toIdObject(shipment.quote),
      "sale_lines": shipment.sale_lines,
      "agents": this.toArrayOfIdObjects(shipment.agents),
      "status": shipment.status,
      "reference": shipment.reference,
      "incoterms": shipment.incoterms,
      "business_type": shipment.business_type ? shipment.business_type : undefined,
      "shipper": this.toIdObject(shipment.shipper),
      "shipper_name": shipment.shipper_name,
      "shipper_phone": shipment.shipper_phone,
      "shipper_email": shipment.shipper_email,
      "consignee": this.toIdObject(shipment.consignee),
      "consignee_name": shipment.consignee_name,
      "consignee_phone": shipment.consignee_phone,
      "consignee_email": shipment.consignee_email,
      "currency": shipment.currency,
      "cargo_description": shipment.cargo_description,
      "cargo_value": shipment.cargo_value,
      "transport_type": shipment.transport_type,
      "containers": this.toShipmentContainer(shipment.containers),
      "truck_quantity": shipment.truck_quantity,
      "truck_fuel_type": shipment.truck_fuel_type ? shipment.truck_fuel_type : undefined,
      "pallets": this.toShipmentPallets(shipment.pallets),
      "colli": this.toShipmentPackages(shipment.colli),
      "insurance": shipment.insurance ? shipment.insurance : undefined,
      "insured_value": shipment.insured_value,
      "pickup_location": this.toLocation(shipment.pickup_location),
      "estimated_pickup_date": shipment.estimated_pickup_date,
      "actual_pickup_date": shipment.actual_pickup_date,
      "delivery_location": this.toLocation(shipment.delivery_location),
      "estimated_delivery_date": shipment.estimated_delivery_date,
      "actual_delivery_date": shipment.actual_delivery_date,
      "loading_reference": shipment.loading_reference,
      "unloading_reference": shipment.unloading_reference,
      "tracking_link": shipment.tracking_link,
      "proof_of_delivery": this.toIdObject(shipment.proof_of_delivery),
      "port_of_loading": this.toLocation(shipment.port_of_loading),
      "estimated_departure_date": shipment.estimated_departure_date,
      "actual_departure_date": shipment.actual_departure_date,
      "port_of_discharge": this.toLocation(shipment.port_of_discharge),
      "initial_estimated_arrival_date": shipment.initial_estimated_arrival_date,
      "estimated_arrival_date": shipment.estimated_arrival_date,
      "actual_arrival_date": shipment.actual_arrival_date,
      "shipping_line": this.toShippingLine(shipment.shipping_line),
      "vessel_name": shipment.vessel_name,
      "vessel_imo": shipment.vessel_imo,
      "booking_number": shipment.booking_number,
      "master_bl_number": shipment.master_bl_number,
      "house_bl_number": shipment.house_bl_number,
      "internal_notes": shipment.internal_notes,
      "public_documents": this.toDocuments(shipment.public_documents),
      "internal_documents": this.toDocuments(shipment.internal_documents),
      "invoices": this.toInvoices(shipment.invoices)
    };
  }

  static toOutboundShipmentRequest(shipment) {
    return {
      "customer": this.toIdObject(shipment.customer),
      "reference": shipment.reference,
      "transport_type": shipment.transport_type,
      "pickup_location": this.toLocation(shipment.pickup_location)
    };
  }

  static toShipmentRequestTransport(shipment) {
    return {
      "status": shipment.status,
      "estimated_pickup_date": shipment.estimated_pickup_date,
      "actual_pickup_date": shipment.actual_pickup_date,
      "estimated_delivery_date": shipment.estimated_delivery_date,
      "actual_delivery_date": shipment.actual_delivery_date,
      "loading_reference": shipment.loading_reference,
      "unloading_reference": shipment.unloading_reference,
      "tracking_link": shipment.tracking_link,
      "proof_of_delivery": this.toIdObject(shipment.proof_of_delivery),
      "estimated_departure_date": shipment.estimated_departure_date,
      "actual_departure_date": shipment.actual_departure_date,
      "estimated_arrival_date": shipment.estimated_arrival_date,
      "actual_arrival_date": shipment.actual_arrival_date
    };
  }

  static toShipmentRequestLoad(shipment) {
    return {
      "status": shipment.status,
      "pallets": this.toShipmentPallets(shipment.pallets),
      "colli": this.toShipmentPackages(shipment.colli)
    };
  }

  static toLocation(location) {
    if (!location || !location.type) {
      return;
    }

    if (location.type === "address") {
      if (!location.address && !location.postal_code && !location.city && (!location.country || !location.country.country_code)) {
        return;
      }
      return {
        "type": location.type,
        "address": location.address,
        "postal_code": location.postal_code,
        "city": location.city,
        "country": {
          "country_code": location.country ? location.country.country_code : undefined
        }
      };
    }

    if (location.type === "port") {
      if (!location.port_code) {
        return;
      }
      return {
        "type": location.type,
        "port_code": location.port_code
      };
    }

    if (location.type === "warehouse") {
      if (!location.warehouse_code) {
        return;
      }
      return {
        "type": location.type,
        "warehouse_code": location.warehouse_code
      };
    }
  }

  static toShippingLine(shipping_line) {
    if (!shipping_line || !shipping_line.code) {
      return;
    }
    return {
      "code": shipping_line.code
    };
  }

  static toQuoteContainer(containers) {
    var new_containers = [];

    if (!containers) {
      return new_containers;
    }

    for (const container of containers) {
      new_containers.push({
        "quantity": container.quantity,
        "size": container.size,
        "type": container.type
      });
    }
    return new_containers;
  }

  static toQuotePallets(pallets) {
    var new_pallets = [];

    if (!pallets) {
      return new_pallets;
    }

    for (const pallet of pallets) {
      new_pallets.push({
        "hs_codes": pallet.hs_codes,
        "length_cm": pallet.length_cm,
        "width_cm": pallet.width_cm,
        "height_cm": pallet.height_cm,
        "weight_kg": pallet.weight_kg,
        "stackable": pallet.stackable
      });
    }
    return new_pallets;
  }

  static toQuotePackages(packages) {
    var new_packages = [];

    if (!packages) {
      return new_packages;
    }

    for (const _package of packages) {
      new_packages.push({
        "package_number": _package.package_number,
        "loading_number": _package.loading_number,
        "parts": this.toQuoteParts(_package.parts)
      });
    }
    return new_packages;
  }

  static toQuoteParts(parts) {
    var new_parts = [];

    if (!parts) {
      return new_parts;
    }

    for (const part of parts) {
      new_parts.push({
        "part_number": part.part_number,
        "order_number": part.order_number,
        "quantity": part.quantity,
        "country_of_origin": part.country_of_origin,
        "reference": part.reference,
        "part_price": part.part_price,
        "description": part.description
      });
    }
    return new_parts;
  }

  static toShipmentContainer(containers) {
    var new_containers = [];

    if (!containers) {
      return new_containers;
    }

    for (const container of containers) {
      new_containers.push({
        "container_number": container.container_number,
        "size": container.size,
        "type": container.type,
        "gross_weight_kg": container.gross_weight_kg
      });
    }
    return new_containers;
  }
  
  static toShipmentPallets(pallets) {
    var new_pallets = [];

    if (!pallets) {
      return new_pallets;
    }

    for (const pallet of pallets) {
      new_pallets.push({
        "hs_codes": pallet.hs_codes,
        "length_cm": pallet.length_cm,
        "width_cm": pallet.width_cm,
        "height_cm": pallet.height_cm,
        "weight_kg": pallet.weight_kg,
        "stackable": pallet.stackable
      });
    }
    return new_pallets;
  }

  static toShipmentPackages(packages) {
    var new_packages = [];

    if (!packages) {
      return new_packages;
    }

    for (const _package of packages) {
      new_packages.push({
        "package_number": _package.package_number,
        "loading_number": _package.loading_number,
        "parts": this.toShipmentParts(_package.parts)
      });
    }
    return new_packages;
  }

  static toShipmentParts(parts) {
    var new_parts = [];

    if (!parts) {
      return new_parts;
    }

    for (const part of parts) {
      new_parts.push({
        "part_number": part.part_number,
        "order_number": part.order_number,
        "quantity": part.quantity,
        "unloading_quantity": part.unloading_quantity,
        "arrival_date": part.arrival_date,
        "ready_date": part.ready_date,
        "dispatch_date": part.dispatch_date,
        "loading_quantity": part.loading_quantity,
        "country_of_origin": part.country_of_origin,
        "reference": part.reference,
        "part_price": part.part_price,
        "description": part.description
      });
    }
    return new_parts;
  }

  static toDocuments(documents) {
    var new_documents = [];

    if (!documents) {
      return new_documents;
    }

    for (const document of documents) {
      new_documents.push(this.toIdObject(document));
    }

    return new_documents;
  }

  static toInvoices(invoices) {
    var new_invoices = [];

    if (!invoices) {
      return new_invoices;
    }

    for (const invoice of invoices) {
      new_invoices.push({
        "status": invoice.status,
        "invoice_number": invoice.invoice_number,
        "total_amount": invoice.total_amount,
        "document": this.toIdObject(invoice.document)
      });
    }

    return new_invoices;
  }

  static toArrayOfIdObjects(data) {
    if (!data) {
      return;
    }

    return data.map(d => this.toIdObject(d));
  }

  static toIdObject(data) {
    if (!data || !data.id) {
      return;
    }

    return {
      "id": data.id
    };
  }

  static toByteArray(data) {
    var bytes = [];
    for (var i = 0; i < data.length; ++i) {
      var code = data.charCodeAt(i);
      bytes = bytes.concat([code]);
    }
    return bytes;
  }
}

export default Mapper;
