import React from 'react';
import TextField from '@material-ui/core/TextField';
import MultilineTextField from '../../../../shared/components/multiline_textfield';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import Mapper from '../../../../shared/util/mapper'
import Converter from '../../../../shared/util/converter'

class CargoTab extends React.Component {

  handleChange(e) {
    this.props.shipment[e.target.name] = e.target.value;
    if(this.props.shipment.transport_type !== "fcl") {
      this.props.shipment.containers = [];
    }
    this.props.updateShipment(this.props.shipment);
  }

  render() {
    return (
      <div>

        <div className="row row-small">
          <div className="col-md-6 col-lg-4">
            <FormControl
              variant="outlined"
              margin="dense"
              fullWidth>
              <InputLabel>Currency *</InputLabel>
              <Select
                native
                required
                label="Currency *"
                value={this.props.shipment.currency}
                name="currency"
                onChange={this.handleChange.bind(this)} >
                  {Object.keys(Mapper.currencies()).map(currency => {
                    return (<option key={"currency_"+currency} value={currency}>{Mapper.fromCurrency(currency)}</option>);
                  })}
              </Select>
            </FormControl>
          </div>
          <div className="col-md-6 col-lg-4">
            <TextField
              label="Cargo Value"
              variant="outlined"
              fullWidth
              margin="dense"
              value={this.props.shipment.cargo_value}
              name="cargo_value"
              onChange={this.handleChange.bind(this)}
              InputProps={{
                inputComponent: Converter.numberFormatter,
                startAdornment: <InputAdornment position="start">{Mapper.fromCurrencyShort(this.props.shipment.currency)}</InputAdornment>,
              }}
            />
          </div>
        </div>

        <div className="row row-small">
          <div className="col-md-6 col-lg-4">
            <FormControl
              variant="outlined"
              margin="dense"
              fullWidth>
              <InputLabel>Insurance</InputLabel>
              <Select
                native
                label="Insurance"
                value={this.props.shipment.insurance}
                name="insurance"
                onChange={this.handleChange.bind(this)} >
                  <option key="insurance_empty" value=""></option>
                  {Object.keys(Mapper.insuranceTypes()).map(insurance_type => {
                    return (<option key={"insurance_type_"+insurance_type} value={insurance_type}>{Mapper.fromInsuranceType(insurance_type)}</option>);
                  })}
              </Select>
            </FormControl>
          </div>
          <div className="col-md-6 col-lg-4">
            <TextField
              label="Insured Value"
              variant="outlined"
              fullWidth
              margin="dense"
              value={this.props.shipment.insured_value}
              name="insured_value"
              onChange={this.handleChange.bind(this)}
              InputProps={{
                inputComponent: Converter.numberFormatter,
                startAdornment: <InputAdornment position="start">{Mapper.fromCurrencyShort(this.props.shipment.currency)}</InputAdornment>,
              }}
            />
          </div>
        </div>
        
        <div className="row row-small">
          <div className="col-md-12 col-lg-8">
            <MultilineTextField
              label="Cargo description"
              variant="outlined"
              fullWidth
              min_rows={4}
              margin="dense"
              value={this.props.shipment.cargo_description}
              name="cargo_description"
              onChange={this.handleChange.bind(this)}
              InputLabelProps={{
                shrink: this.props.shipment.cargo_description !== undefined,
              }}
            />
          </div>
        </div>
      
      </div>
      
    );
  }
}

export default CargoTab;
