import React from 'react';
import ReactTimeAgo from 'react-time-ago'
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { getQuotes  } from '../../../services/quote_service';
import { isReadOnly, hasType } from '../../../services/authentication_service';
import Mapper from '../../../util/mapper';
import Converter from '../../../util/converter';
import RowDataTable from '../../../components/row_data_table';
import TableUtil from '../../../util/table_util';

class Quotes extends React.Component {

  getActionRequiredDot(quote) {
    if(this.isActionRequired(quote)) {
      return (<i style={{fontSize: "8px", verticalAlign: "middle"}} className="fas fa-circle"></i>);
    }
    return <span/>;
  }

  isActionRequired(quote) {
    if(hasType("customer")) {
      // Only show a dot for the custmer if the action is not edit (only true for status quoted)
      return quote.action_allowed && quote.status === 'quoted';
    }
    return quote.action_allowed;
  }

  onPaginationChanged(page, size) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("page", page);
    searchParams.set("size", size);
    window.history.replaceState(window.history.state, '', `${location.pathname}?${searchParams.toString()}`);
  }

  onSortChanged(sort) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("sort", sort);
    window.history.replaceState(window.history.state, '', `${location.pathname}?${searchParams.toString()}`);
  }

  onFilterChanged(filters) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("filters", JSON.stringify(filters));
    window.history.replaceState(window.history.state, '', `${location.pathname}?${searchParams.toString()}`);
  }
  
  getQuoteHeaders() {
    var headers = [];
  
    headers.push(TableUtil.getTextColumn(
      "action_required",
      "",
      (params) => this.getActionRequiredDot(params.data),
      { width: 50, minWidth: 50, disable: true, lockVisible: true, pinned: "left" }
    ));

    headers.push(TableUtil.getTextColumn("id", "ID", undefined, { pinned: "left" }));

    headers.push(TableUtil.getDateColumn(
      "created_at",
      "Created",
      (params) => <ReactTimeAgo date={new Date(params.value)} />,
    ));
    
    headers.push(TableUtil.getSetColumn(
      "status",
      "Status",
      (params) => <span className={"badge " + Mapper.toQuoteStatusBadge(params.value)} >{Mapper.fromQuoteStatus(params.value)}</span>,
      Object.keys(Mapper.filterableQuoteStatuses()),
      (value) => Mapper.fromQuoteStatus(value)
    ));

    if(hasType("forwarder") || hasType("admin")) {
      headers.push(TableUtil.getTextColumn("customer.name", "Customer"));
    }

    if(hasType("forwarder") || hasType("admin") || hasType("customer")) {
      headers.push(TableUtil.getTextColumn("created_by.name", "Created by"));
    }
    
    headers.push(TableUtil.getTextColumn("reference", "Reference", p => p && p.value ? p.value : "", { minWidth: 250 }));

    headers.push(TableUtil.getSetColumn(
      "incoterms",
      "Incoterms",
      (params) => Mapper.fromIncotermsShort(params.value),
      Object.keys(Mapper.incoterms()),
      (value) => Mapper.fromIncotermsShort(value)
    ));

    headers.push(TableUtil.getSetColumn(
      "business_type",
      "Type",
      (params) => Mapper.fromBusinessTypeShort(params.value),
      Object.keys(Mapper.businessTypes()),
      (value) => Mapper.fromBusinessTypeShort(value)
    ));

    headers.push(TableUtil.getSetColumn(
      "transport_type",
      "Transport Type",
      (params) => Mapper.fromTransportTypeShort(params.value),
      Object.keys(Mapper.transportTypes()),
      (value) => Mapper.fromTransportTypeShort(value)
    ));
    
    return headers;
  }

  getRows(page, blockSize, sort, filters) {
    return new Promise((resolve, reject) => {
      if(this.props.statuses) {
        filters.push({
          "field": "status",
          "comparator": "in",
          "values": this.props.statuses
        });
      }

      return getQuotes(page, blockSize, sort, JSON.stringify(filters))
      .then((response) => {
        if(!response) {
          reject("no data");
          return;
        }
        resolve({ content: response.content, total_elements: response.total_elements });
      }).catch(error => {
        reject(error);
      });
    });
  };

  getPage() {
    const params = new URLSearchParams(this.props.location.search);
    return params.get('page');
  }

  getPageSize() {
    const params = new URLSearchParams(this.props.location.search);
    return params.get('size');
  }

  getSort() {
    const params = new URLSearchParams(this.props.location.search);
    return params.get('sort');
  }

  getFilters() {
    const params = new URLSearchParams(this.props.location.search);
    var filters = params.get('filters');
    if(filters) {
      return JSON.parse(filters);
    }
  }

  render() {
    return (
      <div className="container-body">

        <div className="row">
          <div className="col-md">
            <div className="form-header">
              <label>Quotes</label>
            </div>
          </div>
        </div>
        
        { hasType("customer") && !isReadOnly() &&
          <div className="row">
            <div className="col-sm">
              <Button
                variant="outlined"
                color="primary"
                onClick={this.props.newQuote}
                startIcon={<Icon>add</Icon>}>
                New
              </Button>
            </div>
          </div>
        }

        <div className="row">
          <div className="col-sm">
            <RowDataTable
              name="quotes_table_state"
              rowModelType="serverSide"
              rowData={{ totalElements: 0, ready: false }}
              getRows={this.getRows.bind(this)}
              columnDefs={this.getQuoteHeaders()}
              overlayNoRowsTemplate={this.props.emptyMessage}
              pagination={true}
              initialPage={this.getPage()}
              initialPageSize={this.getPageSize()}
              initialSort={this.getSort()}
              initialFilters={this.getFilters()}
              defaultPageSize={100}
              onRowClicked={this.props.openQuote.bind(this)}
              onPaginationChanged={this.onPaginationChanged.bind(this)}
              onSortChanged={this.onSortChanged.bind(this)}
              onFilterChanged={this.onFilterChanged.bind(this)}
            />
          </div>
        </div>

        
      </div>
    );
  }
}

export default Quotes;
