import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LoadingSelectField from "../../../../shared/components/loading_select_field";

class WareHouseField extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      location: this.props.location,
      label: this.props.label ? this.props.label : "Warehouse"
    };
  }
  
  firstLetter(option) {
    return option.name[0].toUpperCase();
  }

  handleAutoCompleteChange(e, newValue) {
    if(newValue) {
      this.state.location = newValue;
    } else {
      this.state.location = { warehouse_code: "" };
    }
    this.state.location.type = "warehouse";
    this.setState({ location: this.state.location }, () => {
      this.props.onLocationUpdate(this.props.property, this.state.location);
    });
  }

  getOptionLabel(value) {
    var warehouse;
    if(value.warehouse_code) {
      warehouse = this.props.warehouses.find(w => w.warehouse_code === value.warehouse_code);
    }
    if(!warehouse) {
      return "";
    }
    return warehouse.name + " (" + warehouse.city + ", " + warehouse.country.name + ")";
  }

  render() {
    return (
      <div>

        { this.props.warehouses &&
          <Autocomplete
            key="location"
            options={this.props.warehouses.sort((a, b) => -b.name[0].localeCompare(a.name[0]))}
            groupBy={this.firstLetter.bind(this)}
            getOptionLabel={(option) => this.getOptionLabel(option)}
            value={this.state.location}
            onChange={this.handleAutoCompleteChange.bind(this)}
            renderInput={(params) => 
              <TextField {...params}
                label={this.state.label}
                variant="outlined"
                required={this.props.required}
                fullWidth
                margin="dense"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />                      
            }
          />
        }
        { !this.props.warehouses &&
          <LoadingSelectField
            label={this.state.label}
            shrink={false}
          />
        }

      </div>
        
    );
  }
}

export default WareHouseField;
