import React from 'react';
import DocumentLink from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import fileDownload from 'js-file-download';
import { getInvoicesOfResource, deleteInvoice } from '../services/invoice_service.js';
import { getAllAccounts } from '../services/account_service';
import ErrorHandler from '../util/error_handler.js';
import AsyncButton from './async_button.js';
import confirmGenerateInvoice from './confirm_generate_invoice.js'
import confirmInvoice from './confirm_invoice.js'
import confirmSendInvoice from './confirm_send_invoice.js'
import confirmViewInvoice from './confirm_view_invoice.js'
import LoadingContent from './loading_content.js';
import Converter from '../util/converter.js';
import RowDataTable from './row_data_table.js';
import TableUtil from '../util/table_util.js';
import Mapper from '../util/mapper.js';
import confirm from './confirm.js'
import alertDialog from './alert';
import EventBus from './event_bus';

class InvoiceContainer extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      invoices: undefined
    };
  }

  componentWillMount() {
    this.getInvoices();
    this.getAllAccounts();
  }

  getInvoices() {
    getInvoicesOfResource(this.props.resource_type, this.props.resource_id).then((invoices) => {
      if (invoices) {
        this.setState({ invoices: invoices });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }
  
  getAllAccounts() {
    getAllAccounts().then((response) => {
      if (response) {
        this.setState({ accounts: response.accounts });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }
  
  generateInvoice() {

    var invoice_lines = [ {} ];
    if(this.props.getbaseInvoiceLines) {
      invoice_lines = this.props.getbaseInvoiceLines();
    }

    var comment = undefined;
    if(this.props.getBaseComment) {
      comment = this.props.getBaseComment();
    }

    var invoice_request = {
      counterparty: this.props.counterparty,
      resource_type: this.props.resource_type,
      resource_id: this.props.resource_id,
      invoice_date: new Date().toISOString().slice(0,10),
      payment_term_days: this.props.counterparty.company_details.payment_term_days,
      invoice_lines: invoice_lines,
      comment: comment
    };

    confirmGenerateInvoice("Create invoice", invoice_request).then((invoice) => {
      this.state.invoices.push(invoice);
      this.setState({ invoices: this.state.invoices });
      EventBus.dispatch("updateInvoice");
    });
  }

  uploadInvoice() {
    var invoice_request = {
      document: {},
      type: "purchase",
      resource_type: this.props.resource_type,
      resource_id: this.props.resource_id,
    };

    confirmInvoice("Add invoice", invoice_request, this.state.accounts, this.props.uploadDocument, this.props.downloadDocument).then((invoice) => {
      this.state.invoices.push(invoice);
      this.setState({ invoices: this.state.invoices });
      EventBus.dispatch("updateInvoice");
    });
  }

  getInvoicesOfType(type) {
    if(!this.state.invoices) {
      return [];
    }
    return this.state.invoices.filter((invoice) => invoice.type === type);
  }
  
  getGrossTotalInvoiceType(type) {
    var invoicesOfType = this.getInvoicesOfType(type);
    
    var total = 0;
    for(const invoice of invoicesOfType) {
      total += invoice.total_gross_amount;
    }

    return total;    
  }

  getNetTotalInvoiceType(type) {
    var invoicesOfType = this.getInvoicesOfType(type);

    var total = 0;
    for(const invoice of invoicesOfType) {
      total += invoice.total_net_amount;
    }

    return total;    
  }

  getCountOfInvoiceType(type) {
    var invoicesOfType = this.getInvoicesOfType(type);
    if(!invoicesOfType) {
      return "0";
    }
    return invoicesOfType.length.toString();
  }

  getGrossProfit() {
    return this.getGrossTotalInvoiceType("sale") - this.getGrossTotalInvoiceType("purchase");
  }

  getNetProfit() {
    return this.getNetTotalInvoiceType("sale") - this.getNetTotalInvoiceType("purchase");
  }

  getGrossProfitClass() {
    if(this.getGrossProfit() > 0) {
      return "value-positive";
    }
    if(this.getGrossProfit() < 0) {
      return "value-negative";
    }
    return "";
  }

  getNetProfitClass() {
    if(this.getNetProfit() > 0) {
      return "value-positive";
    }
    if(this.getNetProfit() < 0) {
      return "value-negative";
    }
    return "";
  }

  getInvoicesHeaders(type) {
    var headers = [];
  
    headers.push( {
      headerName: "Actions",
      field: "actions",
      cellRenderer: (params) => this.getContainersActionButtons(params, type),
      minWidth: 170,
      width: 170,
      pinned: 'left',
      lockPinned: true,
      lockVisible: true
    });
    
    headers.push(TableUtil.getTextColumn("invoice_number", "Invoice number"));
    headers.push( {
      headerName: "Document",
      field: "document",
      minWidth: 150,
      cellRenderer: (params) => 
        <DocumentLink
          className="file-download-link"
          component="button"
          variant="body2"
          type="button"
          onClick={() => {
            this.props.downloadDocument(params.value).then(blob => {
              fileDownload(blob, params.value.file_name);
            }).catch(error => {
              console.error(error)
              alertDialog("Alert", "Failed to download document.")
            });

          }}
          >
            {params.value ? params.value.file_name : ""}
          </DocumentLink>,
      sortable:  false,
    });
    headers.push( {
      headerName: "Status",
      field: "status",
      cellRenderer: (params) => <span className={"badge " + Mapper.toInvoiceStatusBadge(params.value)} >{Mapper.fromInvoiceStatus(params.value)}</span>,
      minWidth: 150,
      filter: "agSetColumnFilter",
      filterParams: {
        maxNumConditions: 1,
        values: Object.keys(Mapper.invoiceStatuses()),
        valueFormatter: (params) => Mapper.fromInvoiceStatus(params.value)
      },
      floatingFilter: true,
      sortable:  true,
    });
    headers.push(TableUtil.getTextColumn("counterparty.name", "Counterparty"));
    headers.push(TableUtil.getDateColumn(
      "invoice_date",
      "Invoice date",
      (params) => Converter.toDate(params.value),
    ));
    headers.push(TableUtil.getDateColumn(
      "due_date",
      "Due date",
      (params) => {
        var className;
        if(params.data.status == 'open' && new Date(params.value) < new Date())  {
          className = "invoice-due-date-expired"
        }
        return <span className={className}>{Converter.toDate(params.value)}</span>;
      }
    ));
    if(type === "sale") {
      headers.push(TableUtil.getDateColumn(
        "sent_at",
        "Sent at",
        (params) => Converter.toDatetime(params.value),
      ));
    }
    headers.push(TableUtil.getNumberColumn(
      "total_gross_amount",
      "Total gross amount",
      (params) => Converter.toCurrency("eur", params.value),
    ));
    headers.push(TableUtil.getNumberColumn(
      "total_net_amount",
      "Total net amount",
      (params) => Converter.toCurrency("eur", params.value),
    ));
    
    return headers;
  }
  
  getInvoicesRows(type) {
    var invoicesOfType = this.getInvoicesOfType(type);
    var invoices = [];

    invoicesOfType.forEach((invoice, index) => {
      invoices.push({ ... invoice, index: index });
    });

    return { 
      data: invoices,
      totalElements: invoices.length,
      ready: true
     };
  }

  getContainersActionButtons(params, type) {
    return (
      <div className='table-cell-button-container'>
        <IconButton
          className='table-cell-button'
          size="small"
          onClick={() => this.onShow(params.data.id)}>
          <Icon>visibility</Icon>
        </IconButton>
        {type === "sale" &&
        <IconButton
          className='table-cell-button'
          size="small"
          onClick={() => this.onSend(params.data.id)}>
          <Icon>send</Icon>
        </IconButton>
        }
        <IconButton
          className='table-cell-button'
          size="small"
          onClick={() => this.onEdit(params.data.id)}>
          <Icon>edit</Icon>
        </IconButton>
        {type === "sale" &&
        <IconButton
          className='table-cell-button'
          size="small"
          onClick={() => this.onRecreate(params.data.id)}>
          <Icon>autorenew</Icon>
        </IconButton>
        }
        <IconButton
            className='table-cell-button'
            size="small"
            onClick={() => this.onDelete(params.data.id)}>
          <Icon>delete</Icon>
        </IconButton>
      </div>
    );
  }

  onShow(id) {
    var invoice_request = this.state.invoices.find(i => i.id === id);
    confirmViewInvoice(`View invoice ${invoice_request.invoice_number}`, invoice_request);
  }

  onSend(id) {
    var invoice_request = this.state.invoices.find(i => i.id === id);

    confirmSendInvoice(`Send invoice ${invoice_request.invoice_number}`, invoice_request, this.props.counterparty).then((invoice) => {
      var index = this.state.invoices.indexOf(invoice_request);
      this.state.invoices[index] = invoice;
      this.setState({ invoices: this.state.invoices });
    });
  }

  onEdit(id) {
    var invoice_request = this.state.invoices.find(i => i.id === id);
    const clone = JSON.parse(JSON.stringify(invoice_request));

    confirmInvoice(`Edit invoice ${invoice_request.invoice_number}`, clone, this.state.accounts, this.props.uploadDocument, this.props.downloadDocument).then((invoice) => {
      var index = this.state.invoices.indexOf(invoice_request);
      this.state.invoices[index] = invoice;
      this.setState({ invoices: this.state.invoices });
      EventBus.dispatch("updateInvoice");
    });
  }

  onRecreate(id) {
    var invoice_request = this.state.invoices.find(i => i.id === id);
    const clone = JSON.parse(JSON.stringify(invoice_request));

    confirmGenerateInvoice(`Recreate invoice ${invoice_request.invoice_number}`, clone).then((invoice) => {
      var index = this.state.invoices.indexOf(invoice_request);
      this.state.invoices[index] = invoice;
      this.setState({ invoices: this.state.invoices });
      EventBus.dispatch("updateInvoice");
    });
  }

  onDelete(id) {
    var invoice = this.state.invoices.find(i => i.id === id);

    confirm("Please confirm", `Are you sure you want to delete invoice ${invoice.invoice_number}?`).then(() => {
      deleteInvoice(invoice.id).then(() => {
        var index = this.state.invoices.indexOf(invoice);
        this.state.invoices.splice(index, 1);
        this.setState({ invoices: this.state.invoices });
        EventBus.dispatch("updateInvoice");
      }).catch(error => {
        ErrorHandler.showError(error);
      });
    });
  }

  render() {
    return (
      <div>

        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>{this.props.title}</label>
              </div>
            </div>
          </div>
          <div className="row row-small">
            <div className="col-md-6 col-lg-6">
              <table className="table table-details" >
                <tbody>
                  <tr className="row-no-top-border-and-padding">
                    <td width={200} className="header-text"></td>
                    <td width={200} className="header-text">Gross</td>
                    <td width={200} className="header-text">Net</td>
                    <td width={200} className="header-text">Invoices</td>
                  </tr>
                  <tr>
                    <td width={200} className="header-text">Sale price</td>
                    <td><LoadingContent mustBeLoaded={this.state.invoices} content={() => Converter.toCurrency("eur", this.getGrossTotalInvoiceType("sale"))} /></td>
                    <td><LoadingContent mustBeLoaded={this.state.invoices} content={() => Converter.toCurrency("eur", this.getNetTotalInvoiceType("sale"))} /></td>
                    <td><LoadingContent mustBeLoaded={this.state.invoices} content={() => this.getCountOfInvoiceType("sale")} /></td>
                  </tr>
                  <tr>
                    <td width={200} className="header-text">Purchase price</td>
                    <td><LoadingContent mustBeLoaded={this.state.invoices} content={() => Converter.toCurrency("eur", this.getGrossTotalInvoiceType("purchase"))} /></td>
                    <td><LoadingContent mustBeLoaded={this.state.invoices} content={() => Converter.toCurrency("eur", this.getNetTotalInvoiceType("purchase"))} /></td>
                    <td><LoadingContent mustBeLoaded={this.state.invoices} content={() => this.getCountOfInvoiceType("purchase")} /></td>
                  </tr>
                  <tr>
                    <td width={200} className="header-text-bold">Profit</td>
                    <td><LoadingContent mustBeLoaded={this.state.invoices} content={() => <span className={this.getGrossProfitClass()}><b>{Converter.toCurrency("eur", this.getGrossProfit())}</b></span>} /></td>
                    <td><LoadingContent mustBeLoaded={this.state.invoices} content={() => <span className={this.getNetProfitClass()}><b>{Converter.toCurrency("eur", this.getNetProfit())}</b></span>} /></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        
        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Sale invoices</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="row row-small">
                <div className="col-md">
                <AsyncButton
                    type="submit"
                    variant="outlined"
                    color="primary"
                    icon="note_add"
                    text="Create"
                    onClick={this.generateInvoice.bind(this)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <RowDataTable
                    name="invoices_sale_invoices"
                    rowData={this.getInvoicesRows("sale")}
                    columnDefs={this.getInvoicesHeaders("sale")}
                    overlayNoRowsTemplate={"There are no sale invoices"}
                  />
                </div>
              </div>

            </div>
          </div>
        </div>
        
        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Purchase invoices</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="row row-small">
                <div className="col-md">
                  <AsyncButton
                    type="submit"
                    variant="outlined"
                    color="primary"
                    icon="upload_file"
                    text="Add"
                    onClick={this.uploadInvoice.bind(this)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <RowDataTable
                    name="invoices_purchase_invoices"
                    rowData={this.getInvoicesRows("purchase")}
                    columnDefs={this.getInvoicesHeaders("purchase")}
                    overlayNoRowsTemplate={"There are no purchase invoices"}
                  />
                </div>
              </div>
              
            </div>
          </div>
        </div>
      
      </div>
    );
  }
}

export default InvoiceContainer;
