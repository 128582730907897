import React from 'react';
import { Prompt } from 'react-router-dom';
import { Link } from 'react-router-dom';
import DomUtil from '../../../shared/util/dom_util';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import { createOutboundShipment } from '../../../shared/services/shipment_service';
import { getWarehouses } from '../../../shared/services/warehouse_service.js';
import { getCurrentAccount, getAllAccounts } from '../../../shared/services/account_service';
import ErrorHandler from '../../../shared/util/error_handler';
import Mapper from '../../../shared/util/mapper'
import confirm from '../../../shared/components/confirm.js'
import ShipmentForm from './components/create_form.js'
import EventBus from '../../../shared/components/event_bus';

class CreateShipment extends React.Component {

  constructor(props) {
    super(props)
    this.shipmentUpdated = this.shipmentUpdated.bind(this);
    this.createShipment = this.createShipment.bind(this);

    const params = new URLSearchParams(props.location.search);

    this.state = {
      shipment: {},
      countries: undefined,
      warehouses: undefined,
      account: undefined,
      accounts: undefined,
      changes: false
    };
  }

  componentWillMount() {
    this.getCurrentAccount();
    this.getAllAccounts();
    this.getWarehouses();
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeunload);
  }

  beforeunload(e) {
    e.preventDefault();
    e.returnValue = true;
  }

  getWarehouses() {
    getWarehouses().then((warehouses) => {
      if (warehouses) {
        this.setState({ warehouses: warehouses });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getCurrentAccount() {
    getCurrentAccount().then((account) => {
      if (account) {
        this.setState({ account: account });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getAllAccounts() {
    getAllAccounts().then((accounts) => {
      if (accounts) {
        this.setState({ accounts: accounts.accounts });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  shipmentUpdated() {
    if(!this.state.changes) {
      window.addEventListener('beforeunload', this.beforeunload);
      this.setState({ changes: true });
    }
  }
  
  createShipment(e) {
    e.preventDefault();
    var form = e.currentTarget;
    confirm("Please confirm", "Are you sure to create this shipment?").then(() => {
      DomUtil.disableFormSubmitButtonClass(form);
      var new_shipment = Mapper.toOutboundShipmentRequest(this.state.shipment);
      createOutboundShipment(new_shipment).then((shipment) => {
        EventBus.dispatch("updateShipment");
        this.setState({ changes: false }, () => {
          this.props.history.push(`${this.getBasePath()}/${shipment.id}`);
        });
      }).catch(error => {
        ErrorHandler.showError(error);
        DomUtil.enableFormSubmitButtonClass(form);
      });
    });
  }

  isLoaded() {
    return this.state.warehouses &&
      this.state.account &&
      this.state.accounts;
  }

  isActivePath() {    
    return this.props.location.pathname.startsWith('/shipments/active');
  }

  getBasePath() {
    if(this.isActivePath()) {
      return '/shipments/active';
    }
    return '/shipments/all';
  }

  getBreadCrumbTitle() {
    if(this.isActivePath()) {
      return 'Active Shipments';
    }
    return 'All Shipments';
  }

  render() {
    return (
      <div>

        <div className="container-content">
        
          <Prompt when={this.state.changes} message="Are you sure you want to leave this page, data is not saved." />

          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.getBasePath()}${this.props.location.search}`}>{this.getBreadCrumbTitle()}</Link></BreadcrumbItem>
            <BreadcrumbItem active>New Shipment</BreadcrumbItem>
          </Breadcrumb>
          
          {this.isLoaded() &&
            <ShipmentForm
              title={"New Shipment"}
              warehouses={this.state.warehouses}
              account={this.state.account}
              accounts={this.state.accounts}
              shipment={this.state.shipment}
              changes={this.state.changes}
              shipmentUpdated={this.shipmentUpdated}
              saveShipment={this.createShipment}
            />
          }
          
          {!this.isLoaded() &&
            <div className="container-body">
              <div className="row">
                <div className="col-md">
                  <div className="form-header">
                    <label>New Shipment</label>
                  </div>
                </div>
              </div>          
              <div className="row row-small">
                <div className="col-md-12 col-lg-8">
                  <Skeleton count={5}/>
                </div>
              </div>
            </div>
          }

        </div>
      </div>
    );
  }
}

export default CreateShipment;
