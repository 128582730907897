import React from 'react';

class MatchUtil extends React.Component {

  static matchNumber(source, property, target) {
    return this.baseMatch(source, property, target, /^[^1-9]+|[^0-9]+|\s+/g); // Only keep digits, without leading zero's
  }

  static match(source, property, target) {
    return this.baseMatch(source, property, target, /^[^a-zA-Z1-9]+|[^a-zA-Z0-9]+|\s+/g); // Only keep letter and digits, without leading zero's
  }

  static baseMatch(source, property, target, regex) {
    var matchers = [
      MatchUtil.match1.bind(this),
      MatchUtil.match2.bind(this),
      MatchUtil.match3.bind(this)
    ];

    for(const matcher of matchers) {
      var results = source.filter(c => matcher(this.cleanup(regex, c[property]), this.cleanup(regex, target)));
      var unique =  [...new Set(results.map(v => v[property]))];
      if(unique.length === 1) {
        return results;
      }
      if(unique.length > 1) {
        return []; // Multiple results, don't continue and return empty array
      }
    }

    return [];
  }

  static match1(n1, n2) {
    return n1 === n2;
  }

  static match2(n1, n2) {
    if(n1.length - n2.length <= 3) {
      return n1.includes(n2);
    }
  }

  static match3(n1, n2) {
    if(n2.length - n1.length <= 3) {      
      return n2.includes(n1);
    }
  }

  static cleanup(regex, value) {
    return value.replace(regex, "");
  }
}

export default MatchUtil;
