import React from 'react';
import { Prompt } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import AsyncButton from '../../components/async_button.js';
import { getShipment, updateShipmentLoad } from '../../services/shipment_service.js';
import ErrorHandler from '../../util/error_handler.js';
import DomUtil from '../../util/dom_util.js';
import Mapper from '../../util/mapper.js';
import EventBus from '../../components/event_bus.js';
import confirmQuestion from '../../components/confirm_question.js'
import LoadColliTab from './components/outbound/load_colli_tab.js'

class LoadShipment extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      current_tab: "colli",
      tabs: [ "colli" ],
      shipment_id: this.props.match.params.shipment_id,
      shipment: undefined,
      changes: false
    };
  }

  componentWillMount() {
    this.getShipment();
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeunload);
  }

  beforeunload(e) {
    e.preventDefault();
    e.returnValue = true;
  }

  updateShipment(shipment) {
    if(!this.state.changes) {
      window.addEventListener('beforeunload', this.beforeunload);
    }
    this.setState({ shipment: shipment, changes: true }, () => {
      this.persistColli();
    });
  }

  getTabName(tab) {
    switch(tab) {
      case "colli":
        return "Colli";
      default:
        return;
    }
  }
  
  getTabClassName(name) {
    var className = "nav-link";

    if(name === this.state.current_tab) {
      className += " active";
    }
    
    return className;
  }

  updateTab(tab) {
    this.setState({ current_tab: tab });
  }

  getStyle(tab) {
    if(tab === this.state.current_tab) {
      return { display: "block" };
    } else {
      return { display: "none" };
    }
  }

  getShipment() {
    getShipment(this.state.shipment_id).then((shipment) => {
      if (shipment) {
        this.setState({ shipment: shipment }, () => {
          this.loadPersistedChanges();
          this.setInitialUnloadingStatus();
        });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  loadPersistedChanges() {
    var persistedColli = this.loadPersistedColli();
    if(persistedColli) {
      this.state.shipment.colli = persistedColli;
      this.setState({ shipment: this.state.shipment, changes: true });
    }
  }

  setInitialUnloadingStatus() {
    for(const _package of this.state.shipment.colli) {
      var complete = true;
      for(const part of _package.parts) {
        if(!part.dispatch_date) {
          complete = false;
        }
      }
      if(complete) {
        _package.loading_status = "complete";
      } else {
        _package.loading_status = "todo";
      }
    }
    this.setState({ shipment: this.state.shipment });
  }

  isLoaded() {
    return this.state.shipment;
  }

  isActivePath() {    
    return this.props.location.pathname.startsWith('/shipments/active');
  }

  getBasePath() {
    if(this.isActivePath()) {
      return '/shipments/active';
    }
    return '/shipments/all';
  }

  getBreadCrumbTitle() {
    if(this.isActivePath()) {
      return 'Active Shipments';
    }
    return 'All Shipments';
  }
  
  persistColli() {
    if(!this.state.shipment || !this.state.shipment.colli) {
      return;
    }
    localStorage.setItem(this.state.shipment.id + "-outbound-load-colli", JSON.stringify(this.state.shipment.colli));
  }

  loadPersistedColli() {
    return JSON.parse(localStorage.getItem(this.state.shipment.id + "-outbound-load-colli"));
  }

  clearPersistedColli() {
    localStorage.removeItem(this.state.shipment.id + "-outbound-load-colli");
  }

  saveOutbound(e) {
    e.preventDefault();
    var form = e.currentTarget;
    confirmQuestion("Please provide", <span>Are you finished with loading of this shipment?</span>).then((response) => {
      DomUtil.disableFormSubmitButtonClass(form);
      var shipment = Mapper.toShipmentRequestLoad(this.state.shipment);
      if(response.answer) {
        shipment.status = "delivered";
        shipment.actual_delivery_date = new Date();
        for(const _package of shipment.colli) {
          for(const part of _package.parts) {
            if(!part.loading_quantity) {
              part.loading_quantity = 0;
            }
          }
        }
      }
      updateShipmentLoad(this.state.shipment_id, shipment).then(() => {
        this.setState({ changes: false }, () => {
          this.clearPersistedColli();
          EventBus.dispatch("updateShipment");
          this.props.history.push(`${this.getBasePath()}/${this.state.shipment_id}${this.props.location.search}`);
        });
      }).catch(error => {
        ErrorHandler.showError(error);
        DomUtil.enableFormSubmitButtonClass(form);
      });
    });
  }

  render() {
    return (
      <div>
        
        <Prompt when={this.state.changes} message="Are you sure you want to leave this page, data is not saved." />

        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.getBasePath()}/${this.props.location.search}`}>{this.getBreadCrumbTitle()}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.getBasePath()}/${this.state.shipment_id}${this.props.location.search}`}>{this.state.shipment_id}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Load</BreadcrumbItem>
          </Breadcrumb>
          
          {this.isLoaded() &&
            <div>
              <div className="container-body">
                <div className="row">
                  <div className="col-md">
                    <div className="form-header">
                      <label>Load outbound Shipment {this.state.shipment_id}</label>
                    </div>
                  </div>
                </div>

                <form autoComplete="off" onSubmit={this.saveOutbound.bind(this)}>
                  <div className="row row-small">
                    <div className="col-md-12 col-lg-12">
                      <div className='button-center-form'>
                        <AsyncButton
                          type="submit"
                          variant="outlined"
                          color="primary"
                          icon="check"
                          text="Save" />
                      </div>
                    </div>
                  </div>
                </form>

                <div className="row row-small">
                  <div className="col-md-12 col-lg-6">
                    <table className="table table-details" >
                      <tbody>
                        <tr className="row-no-top-border-and-padding">
                          <td width={200} className="header-text">Reference</td>
                          <td>{this.state.shipment.reference}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              
              <div className="container-body">
                <div className="row">
                  <div className="col-md">
                    <div className="form-header">
                      <ul className="nav nav-tabs">
                        {this.state.tabs.map(tab => {
                          return (
                            <li key={tab} className="nav-item">
                              <a className={this.getTabClassName(tab)} onClick={this.updateTab.bind(this, tab)}>{this.getTabName(tab)}</a>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="tabs">
                  <div style={ this.getStyle("colli") } >
                    <LoadColliTab
                      shipment={this.state.shipment}
                      stock={this.state.stock}
                      updateShipment={this.updateShipment.bind(this)}
                    />
                  </div>
                </div>
              </div>

            </div>
          }
          
          {!this.isLoaded() &&
            <div className="container-body">
              <div className="row">
                <div className="col-md">
                  <div className="form-header">
                    <label>Load outbound Shipment {this.state.shipment_id}</label>
                  </div>
                </div>
              </div>          
              <div className="row row-small">
                <div className="col-md-12 col-lg-8">
                  <Skeleton count={5}/>
                </div>
              </div>
            </div>
          }

        </div>
      </div>
    );
  }
}

export default LoadShipment;
