import React from 'react';
import AsyncButton from '../../../../shared/components/async_button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Mapper from '../../../../shared/util/mapper'
import WareHouseField from './warehouse_field'

class ShipmentForm extends React.Component {

  constructor(props) {
    super(props)

    this.updateShipment = this.updateShipment.bind(this);
    this.onValueUpdate = this.onValueUpdate.bind(this);

    if(this.props.accounts && this.props.accounts.length === 1) {
      this.props.shipment.customer = this.props.accounts[0];
    }
    if(this.props.warehouses && this.props.warehouses.length === 1) {
      this.props.shipment.pickup_location = {...this.props.warehouses[0], type: "warehouse" };
    }

    this.state = {
      warehouses: this.props.warehouses,
      account: this.props.account,
      accounts: this.props.accounts,
      shipment: this.props.shipment
    };
  }
  
  updateShipment(shipment) {
    this.setState({ shipment: shipment });
    this.props.shipmentUpdated();
  }

  handleChange(e) {
    this.state.shipment[e.target.name] = e.target.value;
    this.updateShipment(this.state.shipment);
  }

  onValueUpdate(property, value) {
    this.state.shipment[property] = value;
    this.updateShipment(this.state.shipment);
  }

  getCustomerAccounts() {
    return this.state.accounts.filter((account) => account.type === 'customer').sort((a, b) => -b.name[0].localeCompare(a.name[0]));
  }

  firstAccountLetter(option) {
    return option.name[0].toUpperCase();
  }

  handleAutoSelectionChanged(property, e, newValue) {
    if(newValue) {
      this.state.shipment[property] = newValue;
    } else {
      this.state.shipment[property] = { id: undefined };
    }
    this.updateShipment(this.state.shipment);
  }

  getAccountOptionLabel(value) {
    var account;
    if(value.id) {
      account = this.state.accounts.find(a => a.id === value.id);
    }
    if(!account) {
      return "";
    }
    return account.name;
  }

  render() {
    return (
      <form autoComplete="off" onSubmit={this.props.saveShipment}>
  
        <div className="container-body">

          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>{this.props.title}</label>
              </div>
            </div>
          </div>
        
          <div className="row row-small">
            <div className="col-md-6 col-lg-4">
              <Autocomplete
                key="id"
                options={this.getCustomerAccounts()}
                groupBy={this.firstAccountLetter.bind(this)}
                getOptionLabel={(option) => this.getAccountOptionLabel(option)}
                value={this.state.shipment.customer}
                disabled={this.state.shipment.id}
                onChange={this.handleAutoSelectionChanged.bind(this, "customer")}
                renderInput={(params) => 
                  <TextField {...params}
                    label="Customer"
                    variant="outlined"
                    required
                    fullWidth
                    margin="dense"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                }
              />
            </div>
          </div>
                                
          <div className="row row-small">
            <div className="col-md-6 col-lg-4">
              <TextField
                label="Reference"
                variant="outlined"
                required
                fullWidth
                margin="dense"
                value={this.state.shipment.reference}
                name="reference"
                onChange={this.handleChange.bind(this)} 
              />
            </div>
          </div>

          <div className="row row-small">
            <div className="col-md-6 col-lg-4">
              <WareHouseField 
                label="Pickup location"
                property={"pickup_location"}
                required={true}
                location={this.state.shipment.pickup_location}
                warehouses={this.state.warehouses}
                onLocationUpdate={this.onValueUpdate}
              />
            </div>
          </div>
          
          <div className="row row-small">
            <div className="col-md-6 col-lg-4">
              <FormControl
                variant="outlined"
                margin="dense"
                fullWidth>
                <InputLabel>Transport type *</InputLabel>
                <Select
                  native
                  required
                  label="Transport type *"
                  value={this.state.shipment.transport_type}
                  name="transport_type"
                  onChange={this.handleChange.bind(this)} >
                    <option key={"transport_type_empty"} value=""></option>
                    {[ "ftl", "ltl" ].map(transport_type => {
                      return (<option key={"transport_type_"+transport_type} value={transport_type}>{Mapper.fromTransportType(transport_type)}</option>);
                    })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <AsyncButton
                type="submit"
                variant="outlined"
                color="primary"
                icon="check"
                text="Create" />
            </div>
          </div>
          
        </div>
        
      </form>
    );
  }
}

export default ShipmentForm;
