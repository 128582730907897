import React from 'react';
import { Link } from 'react-router-dom';
import DocumentLink from '@material-ui/core/Link';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import StringUtil from '../../util/string_util';
import { getInvoice, getInvoiceGroupDocument } from '../../../shared/services/invoice_service.js';
import { getShipmentDocument } from '../../../shared/services/shipment_service.js';
import ErrorHandler from '../../util/error_handler';
import Mapper from '../../../shared/util/mapper';
import Converter from '../../../shared/util/converter'
import fileDownload from 'js-file-download';
import LoadingContent from '../../components/loading_content.js';
import { hasType } from '../../services/authentication_service';

class ShowInvoice extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      invoice_id: this.props.match.params.invoice_id,
      invoice: undefined
    };
  }

  componentDidMount() {
    this.getInvoice();
  }
  
  isOpenPath() {    
    return this.props.location.pathname.startsWith('/invoices/open');
  }

  getBasePath() {
    if(this.isOpenPath()) {
      return '/invoices/open';
    }
    return '/invoices/all';
  }

  getBreadCrumbTitle() {
    if(this.isOpenPath()) {
      return 'Open Invoices';
    }
    return 'All Invoices';
  }

  getInvoice() {
    getInvoice(this.state.invoice_id).then((invoice) => {
      if (invoice) {
        this.setState({ invoice: invoice }, () => {
          this.showDocument();
        });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  downloadDocument() {
    this.getDocument().then(blob => {
      fileDownload(blob, this.state.invoice.document.file_name);
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }
  
  showDocument() {
    this.getDocument().then(blob => {
      var document = blob.slice(0, blob.size, "application/pdf")
      var document_url = URL.createObjectURL(document);
      this.setState({ document_url: document_url });
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getDocument() {
    if(this.state.invoice.resource_type === 'shipment') {
      return getShipmentDocument(this.state.invoice.resource_id, this.state.invoice.document.id);
    }
    if(this.state.invoice.resource_type === 'invoice_group') {
      return getInvoiceGroupDocument(this.state.invoice.resource_id, this.state.invoice.document.id);
    }
  }

  getDueDateClassName() {
    if(this.state.invoice && this.state.invoice.status == 'open' && new Date(this.state.invoice.due_date) < new Date())  {
      return "invoice-due-date-expired"
    }
  }

  getResourceName() {
    if(this.state.invoice && this.state.invoice.resource_type === 'shipment') {
      return "Shipment";
    }
    if(this.state.invoice && this.state.invoice.resource_type === 'invoice_group') {
      return "Invoice group";
    }
  }

  getResourceLink() {
    if(this.state.invoice && this.state.invoice.resource_type === 'shipment') {
      if(hasType("forwarder") || hasType("admin")) {
        return <Link to={`/shipments/all/${this.state.invoice.resource_id}/invoicing`}>Shipment {this.state.invoice.resource_id}</Link>
      } else {
        return <Link to={`/shipments/all/${this.state.invoice.resource_id}`}>Shipment {this.state.invoice.resource_id}</Link>
      }
    }
    if(this.state.invoice && this.state.invoice.resource_type === 'invoice_group') {
      return <Link to={`/accounts/${this.state.invoice.counterparty.id}/invoice-groups/${this.state.invoice.resource_id}`}>Invoice group {StringUtil.shortId(this.state.invoice.resource_id)}</Link>
    }
  }

  render() {
    return (
      <div>

        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.getBasePath()}${this.props.location.search}`}>{this.getBreadCrumbTitle()}</Link></BreadcrumbItem>
            <BreadcrumbItem active>{StringUtil.shortId(this.props.match.params.invoice_id)}</BreadcrumbItem>
          </Breadcrumb>

          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Invoice {StringUtil.shortId(this.props.match.params.invoice_id)}</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-lg-6">
                <table className="table table-details" >
                  <tbody>
                    <tr className="row-no-top-border-and-padding">
                      <td width={200} className="header-text">Invoice number</td>
                      <td><LoadingContent mustBeLoaded={this.state.invoice} content={() => this.state.invoice.invoice_number} /></td>
                    </tr>
                    { (hasType("forwarder") || hasType("admin")) &&
                      <tr>
                        <td width={200} className="header-text">Counterparty</td>
                        <td><LoadingContent mustBeLoaded={this.state.invoice} content={() => this.state.invoice.counterparty.name} /></td>
                      </tr>
                    }
                    <tr>
                      <td width={200} className="header-text">Status</td>
                      <td><LoadingContent mustBeLoaded={this.state.invoice} content={() => <span className={"badge " + Mapper.toInvoiceStatusBadge(this.state.invoice.status)} >{Mapper.fromInvoiceStatus(this.state.invoice.status)}</span>} /></td>
                    </tr>
                    <tr>
                      <td width={200} className="header-text">Invoice date</td>
                      <td><LoadingContent mustBeLoaded={this.state.invoice} content={() => <span>{Converter.toDate(this.state.invoice.invoice_date)}</span>} /></td>
                    </tr>
                    <tr>
                      <td width={200} className="header-text">Due date</td>
                      <td><LoadingContent mustBeLoaded={this.state.invoice} content={() => <span className={this.getDueDateClassName()}>{Converter.toDate(this.state.invoice.due_date)}</span>} /></td>
                    </tr>
                    { (hasType("forwarder") || hasType("admin")) &&
                      <tr>
                        <td width={200} className="header-text">Sent at</td>
                        <td><LoadingContent mustBeLoaded={this.state.invoice} content={() => Converter.toDatetime(this.state.invoice.sent_at)} /></td>
                      </tr>
                    }
                    { (hasType("forwarder") || hasType("admin")) &&
                      <tr>
                        <td width={200} className="header-text">Type</td>
                        <td><LoadingContent mustBeLoaded={this.state.invoice} content={() => Mapper.fromInvoiceType(this.state.invoice.type)} /></td>
                      </tr>
                    }
                    <tr>
                      <td width={200} className="header-text">Total gross amount</td>
                      <td><LoadingContent mustBeLoaded={this.state.invoice} content={() => Converter.toCurrency("eur", this.state.invoice.total_gross_amount)} /></td>
                    </tr>
                    <tr>
                      <td width={200} className="header-text">Total net amount</td>
                      <td><LoadingContent mustBeLoaded={this.state.invoice} content={() => Converter.toCurrency("eur", this.state.invoice.total_net_amount)} /></td>
                    </tr>
                    <tr>
                      <td width={200} className="header-text">Document</td>
                      <td>
                        <LoadingContent mustBeLoaded={this.state.invoice} content={() =>
                          <span>
                            <span className="icon-span"><i className="far fa-file"/></span>
                            <DocumentLink
                              className="file-download-link"
                              component="button"
                              variant="body2"
                              type="button"
                              onClick={this.downloadDocument.bind(this)}
                              >
                                {this.state.invoice.document ? this.state.invoice.document.file_name : "-"}
                            </DocumentLink>
                          </span>
                        } />
                       </td>
                    </tr>
                    { ((hasType("forwarder") || hasType("admin")) || (this.state.invoice && this.state.invoice.resource_type === 'shipment')) &&
                      <tr>
                        <td width={200} className="header-text">{this.getResourceName()}</td>
                        <td><LoadingContent mustBeLoaded={this.state.invoice} content={() => this.getResourceLink()} /></td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
              <div className="col-md-6 col-lg-6">
                <iframe className="invoice-viewer" src={this.state.document_url} type="application/pdf" />
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default ShowInvoice;
