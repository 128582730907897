import React from 'react';
import fileDownload from 'js-file-download';
import DocumentLink from '@material-ui/core/Link';
import Table from '../../../components/table.js';
import { getShipmentDocument } from '../../../services/shipment_service.js';
import { hasType } from '../../../services/authentication_service.js';
import ErrorHandler from '../../../util/error_handler.js';
import Mapper from '../../../util/mapper';
import Converter from '../../../util/converter'

class InvoicesTab extends React.Component {
  

  getInvoiceHeaders() {
    return [
      { name: "Invoice number", key: "invoice_number" },
      { name: "Status", key: "status" },
      { name: "Invoice date", key: "invoice_date" },
      { name: "Due date", key: "due_date" },
      { name: "Total gross amount", key: "total_gross_amount" },
      { name: "Total net amount", key: "total_net_amount" },
      { name: "File", key: "file_name" }
    ];
  }

  getInvoiceRows(type) {
    var invoiceRows = [];
    var invoices = this.props.invoices;
        
    if(!invoices) {
      return [];
    }

    var invoicesOfType = invoices.filter((invoice) => invoice.type === type);
    
    invoicesOfType.forEach(invoice => {
      invoiceRows.push({
        invoice_number: invoice.invoice_number,
        status: Mapper.fromInvoiceStatus(invoice.status),
        total_gross_amount: Converter.toCurrency("eur", invoice.total_gross_amount),
        total_net_amount: Converter.toCurrency("eur", invoice.total_net_amount),
        invoice_date: Converter.toDate(invoice.invoice_date),
        due_date: Converter.toDate(invoice.due_date),
        file_name: 
          <span>
            <span className="icon-span"><i className="far fa-file"/></span>
            <DocumentLink
              className="file-download-link"
              component="button"
              variant="body2"
              type="button"
              onClick={this.downloadDocument.bind(this, invoice.document)}
              >
                {invoice.document.file_name}
            </DocumentLink>
          </span>
      });
    });

    return invoiceRows;
  }

  downloadDocument(document) {
    getShipmentDocument(this.props.shipment.id, document.id).then(blob => {
      fileDownload(blob, document.file_name);
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  render() {
    return (
      <div>

        { (hasType("forwarder") || hasType("admin")) &&
          <div>

            <div className="row row-small">
              <div className="col-md-12 col-lg-12">

                <div className="row row-small">
                  <div className="col-md-12 col-lg-12">
                    <div className="header-text">
                      <label>Sale invoices</label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 col-lg-12">
                    <Table
                      loading_rows={3}
                      headers={this.getInvoiceHeaders()}
                      rows={this.getInvoiceRows("sale")}
                      empty_rows_text="There are no sale invoices"
                      selectable={false}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row row-small">
              <div className="col-md-12 col-lg-12">

                <div className="row">
                  <div className="col-md-12 col-lg-12">
                    <div className="header-text">
                      <label>Purchase invoices</label>
                    </div>
                  </div>
                </div>

                <div className="row row-small">
                  <div className="col-md-12 col-lg-12">
                    <Table
                      loading_rows={3}
                      headers={this.getInvoiceHeaders()}
                      rows={this.getInvoiceRows("purchase")}
                      empty_rows_text="There are no purchase invoices"
                      selectable={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

        { (hasType("customer") || hasType("agent")) &&
          <div className="row row-small">
            <div className="col-md-12 col-lg-6">
              <Table
                loading_rows={3}
                headers={this.getInvoiceHeaders()}
                rows={this.getInvoiceRows("sale")}
                empty_rows_text="There are no invoices"
              />
            </div>
          </div>
        }
      </div>
    );
  }
}

export default InvoicesTab;
