import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Theme from './theme.js';
import Mapper from '../util/mapper.js'
import Converter from '../util/converter.js'
import DocumentUpload from './document_upload.js'
import { createInvoice, updateInvoice } from '../services/invoice_service.js';
import ErrorHandler from '../util/error_handler.js';

class InvoiceDialog extends React.Component {

  constructor(props) {
    super();

    this.state = {
      invoice_request: props.invoice_request,
      accounts: props.accounts
    };
    
    this.formRef = React.createRef();
  }

  submit(e) {
    e.preventDefault();

    if(!this.formRef.current.checkValidity()) {
      this.formRef.current.reportValidity();
      return;
    }

    if(this.state.invoice_request.id) {
      updateInvoice(this.state.invoice_request.id, this.state.invoice_request).then((invoice) => {
        this.props.proceed(invoice);
      }).catch(error => {
        ErrorHandler.showError(error);
      });
    } else {
      createInvoice(this.state.invoice_request).then((invoice) => {
        this.props.proceed(invoice);
      }).catch(error => {
        ErrorHandler.showError(error);
      });
    }
  }
  
  handleChange(e) {
    this.state.invoice_request[e.target.name] = e.target.value;
    this.setState({ invoice_request: this.state.invoice_request });
  }

  documentUploaded(document) {
    this.state.invoice_request.document = document;
    this.setState({ invoice_request: this.state.invoice_request });
  }

  documentCleared() {
    this.state.invoice_request.document.id = null;
    this.state.invoice_request.document.file_name = null;
    this.setState({ invoice_request: this.state.invoice_request });
  }

  firstAccountLetter(option) {
    return option.name[0].toUpperCase();
  }

  getCounterpartyOptionLabel(value) {
    var counterparty;
    if(value.id) {
      counterparty = this.state.accounts.find(a => a.id === value.id);
    }
    if(!counterparty) {
      return "";
    }
    return counterparty.name;
  }

  handleCounterpartySelectionChanged(e, newValue) {
    if(newValue) {
      this.state.invoice_request.counterparty = newValue;
    } else {
      this.state.invoice_request.counterparty = { id: undefined };
    }

    this.setState({ invoice_request: this.state.invoice_request });
  }

  getSortedAccounts() {
    return this.state.accounts.sort((a, b) => -b.name[0].localeCompare(a.name[0]));
  }

  render() {
    return (
      <Theme>
        <Dialog fullWidth={true} maxWidth={"md"} open={this.props.show} onClose={this.props.dismiss} >
          <DialogTitle>{this.props.title}</DialogTitle>
          <DialogContent>
            <div className="row">
              <div className="col-md">

                <form autoComplete="off" ref={this.formRef} onSubmit={this.submit.bind(this)}>
                  
                  <div className="row">
                    <div className="col-md">

                      <div className="row">
                        <div className="col-md-6">
                          <DocumentUpload 
                            title="Invoice document"
                            disabled={this.state.invoice_request.type === "sale"}
                            document={this.state.invoice_request.document}
                            uploadDocument={this.props.uploadDocument}
                            downloadDocument={this.props.downloadDocument}
                            documentUploaded={this.documentUploaded.bind(this)}
                            documentCleared={this.documentCleared.bind(this)}
                            />
                        </div>
                        <div className="col-md-6">
                          <Autocomplete
                            key="counterparty"
                            options={this.getSortedAccounts()}
                            groupBy={this.firstAccountLetter.bind(this)}
                            getOptionLabel={(option) => this.getCounterpartyOptionLabel(option)}
                            value={this.state.invoice_request.counterparty}
                            disabled={this.state.invoice_request.type === "sale"}
                            onChange={this.handleCounterpartySelectionChanged.bind(this)}
                            renderInput={(params) => 
                              <TextField {...params}
                                label="From"
                                variant="outlined"
                                fullWidth
                                required
                                margin="dense"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password',
                                }}
                              />
                            }
                          />
                        </div>
                      </div>
                    
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="col-md-6">
                      <TextField
                        label="Invoice number"
                        variant="outlined"
                        required
                        fullWidth
                        margin="dense"
                        name="invoice_number"
                        disabled={this.state.invoice_request.type === "sale"}
                        value={this.state.invoice_request.invoice_number}
                        onChange={this.handleChange.bind(this)}
                      />
                    </div>
                    <div className="col-md-6">
                      <FormControl
                        variant="outlined"
                        margin="dense"
                        fullWidth>
                        <InputLabel>Status *</InputLabel>
                        <Select
                          native
                          required={true}
                          label="Status"
                          name="status"
                          value={this.state.invoice_request.status}
                          onChange={this.handleChange.bind(this)} >
                            <option key={"status_empty"} value=""></option>
                            {Object.keys(Mapper.invoiceStatuses()).map(status => {
                              return (<option key={"status_" + status} value={status}>{Mapper.fromInvoiceStatus(status)}</option>);
                            })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="col-md-6">
                      <TextField
                        label="Total gross amount"
                        variant="outlined"
                        required
                        fullWidth
                        margin="dense"
                        name="total_gross_amount"
                        disabled={this.state.invoice_request.type === "sale"}
                        value={this.state.invoice_request.total_gross_amount}
                        onChange={this.handleChange.bind(this)}
                        InputProps={{
                          inputComponent: Converter.numberFormatter,
                          startAdornment: <InputAdornment position="start">€</InputAdornment>,
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <TextField
                        label="Total net amount"
                        variant="outlined"
                        required
                        fullWidth
                        margin="dense"
                        name="total_net_amount"
                        disabled={this.state.invoice_request.type === "sale"}
                        value={this.state.invoice_request.total_net_amount}
                        onChange={this.handleChange.bind(this)}
                        InputProps={{
                          inputComponent: Converter.numberFormatter,
                          startAdornment: <InputAdornment position="start">€</InputAdornment>,
                        }}
                      />
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="col-md-6">
                      <TextField
                        label="Invoice date"
                        variant="outlined"
                        required
                        fullWidth
                        margin="dense"
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="invoice_date"
                        disabled={this.state.invoice_request.type === "sale"}
                        value={this.state.invoice_request.invoice_date}
                        onChange={this.handleChange.bind(this)}
                      />
                    </div>
                    <div className="col-md-6">
                      <TextField
                        label="Due date"
                        variant="outlined"
                        required
                        fullWidth
                        margin="dense"
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="due_date"
                        disabled={this.state.invoice_request.type === "sale"}
                        value={this.state.invoice_request.due_date}
                        onChange={this.handleChange.bind(this)}
                      />
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.dismiss()} color="primary">Cancel</Button>
            <Button onClick={this.submit.bind(this)} color="primary">Save</Button>
          </DialogActions>
        </Dialog>
      </Theme>
    )
  }
}

InvoiceDialog.propTypes = {
  show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
  cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
  dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
  invoice_request: PropTypes.object,
  accounts: PropTypes.array,
  uploadDocument: PropTypes.func,
  downloadDocument: PropTypes.func,
  title: PropTypes.string,
  fields: PropTypes.array
}

export default confirmable(InvoiceDialog);
